import React from "react";
import { Link } from "@reach/router";
import Pricing from "../components/pricing";
import Testimony from "../components/carouseltestimony";
import Footer from "../components/footer";
import { Timeline, Events, Event } from "vertical-timeline-component-react";

const customTheme = {
  borderDotColor: "#ffffff",
  descriptionColor: "#ffffff",
  dotColor: "#ffffff",
  eventColor: "#ffffff",
  lineColor: "#ffffff",
  subtitleColor: "#ffffff",
  titleColor: "#ffffff",
  yearColor: "#ffffff",
};

const data = [
  {
    title: "Title 1",
    date: "1.1.2020",
    alt: "Basic",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, nunc ut aliquam aliquam, nunc nisl aliquet nisl, eget aliquam nisl nisl sit amet lorem. Sed euismod, nunc ut aliquam aliquam, nunc nisl aliquet nisl, eget aliquam nisl nisl sit amet lorem.",
    pictureUrl: "./img/price.jpg",
  },
  {
    title: "Title 2",
    date: "2.2.2020",
    alt: "Basic",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, nunc ut aliquam aliquam, nunc nisl aliquet nisl, eget aliquam nisl nisl sit amet lorem. Sed euismod, nunc ut aliquam aliquam, nunc nisl aliquet nisl, eget aliquam nisl nisl sit amet lorem.",
    pictureUrl: "./img/price.jpg",
  },
];

export default () => (
  <div>
    <section
      className="jumbotron breadcumb center"
      style={{ backgroundImage: `url(${"./img/bg-3.jpg"})` }}
    >
      <div className="mainbreadcumb">
        <div className="container-fluid">
          <div className="row m-10-hor">
            <div className="col-md-6">
              <h1>Novinky</h1>
            </div>
            <div className="col-md-6">
              <div className="list">
                <Link className="link" to="/home">
                  Domov
                </Link>
                <span className="dash">/</span>
                <span>Novinky</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="container-fluid black">
      <div className="row m-10-hor">
        <div className="col-md-5">
          <div className="heading centered">
            Pridáme <span className="br"> </span> čoskoro
            <span className="br"></span> <span className="br"></span>
          </div>
        </div>

        <div className="col-md-7">
          <div className="content">
            Zatiaľ sa v tejto sekcii nevyskytujú žiadne novinky.
          </div>
          <div className="content">Ďakujeme za pochopenie.</div>
        </div>
      </div>
    </section>

    {/* <section className="container-fluid black_more">

      <div className="row m-10-hor">
        <div className="col-md-12">
          <div className="container">
          <Timeline theme={customTheme} collapse withoutDay>
    <Events
     startDate="2020/12/02"
     defaultClosed
     active
    >
     <Event
      title="Lorem Ipsum"
      description={[
       "Is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard",
       'Is simply dummy text of the printing and typesetting industry.',
      ]}
     />
    </Events>
   </Timeline>
          </div>
      </div>
      </div>
    </section> */}

    <Testimony />
    <Footer />
  </div>
);
