import React from "react";
import { Link } from "@reach/router";
import posed from "react-pose";
import ImageGallery from "../components/ImageGallery";
import Bannercontact from "../components/bannercontact";
import Testimony from "../components/carouseltestimony";
import Footer from "../components/footer";

const ListContainer = posed.div({
  enter: { staggerChildren: 20 },
  exit: { staggerChildren: 20, staggerDirection: 0 },
});

const Item = posed.section({
  enter: { y: 0, opacity: 1 },
  exit: { y: 5, opacity: 0 },
});

export default () => (
  <ListContainer>
    <Item
      className="jumbotron breadcumb"
      style={{
        backgroundImage: `url(${"./img/bg-4.jpg"})`,
        backgroundPosition: "bottom",
      }}
    >
      <div className="mainbreadcumb">
        <div className="container-fluid">
          <div className="row m-10-hor">
            <div className="col-md-6">
              <h1>Referencie</h1>
            </div>
            <div className="col-md-6">
              <div className="list">
                <Link className="link" to="/home">
                  Domov
                </Link>
                <span className="dash">/</span>
                <span>Referencie</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Item>

    {/* <Item className='container-fluid black_more'>
    <div className='row m-10-hor'>
      <div className='col-12'>
        <div className='AppContainer'>
          <ImageGallery />
        </div>
      </div>
    
    </div>
  </Item> */}
    <Item className="container-fluid black_more">
      {/* <section className="container-fluid black">
        <div className="row m-10-hor">
          <div className="col-md-5">
            <div className="heading centered">
              Pridáme <span className="br"> </span> čoskoro
              <span className="br"></span> <span className="br"></span>
            </div>
          </div>

          <div className="col-md-7">
            <div className="content">
              Zatiaľ sa v tejto sekcii nevyskytujú žiadne Referencie.
            </div>
            <div className="content">Ďakujeme za pochopenie.</div>
          </div>
        </div>
      </section> */}

      <section className="container-fluid pt-0">
        <div className="row m-10-hor">
          <div className="col-md-6 px-0 centerImage">
            <img
              src="./img/logos/invest.jpg"
              className=""
              alt="#"
              width="50%"
            />
          </div>

          <div className="col-md-6 centered p-md-5 pt-5">
            <div>
              <div className="heading">IN VEST s.r.o.</div>
              <p className="mt-3"></p>
            </div>
          </div>

          <div className="col-md-6 px-0 centerImage">
            <img src="./img/logos/stavmat.png" className="" alt="#" />
          </div>

          <div className="col-md-6 centered p-md-5 pt-5">
            <div>
              <div className="heading">STAVMAT STAVEBNINY, s.r.o.</div>
              <p className="mt-3">
                Sieť stavebnín a stavebných centier na Slovensku, v Českej
                republike a v Maďarsku. Poskytujeme služby profesionálom,
                stavebným firmám aj koncovým zákazníkom.
              </p>
            </div>
          </div>

          <div className="col-md-6 px-0 centerImage">
            <img
              src="./img/logos/viasat.png"
              className=""
              alt="#"
              width="50%"
            />
          </div>

          <div className="col-md-6 centered p-md-5 pt-5">
            <div>
              <div className="heading">Viasat World</div>
            </div>
          </div>

          <div className="col-md-6 px-0 centerImage">
            <img
              src="./img/logos/strecharska.png"
              className=""
              alt="#"
              width="50%"
            />
          </div>

          <div className="col-md-6 centered p-md-5 pt-5">
            <div>
              <div className="heading">Prvá strechárska s. r. o.</div>
              <p className="mt-3"></p>
            </div>
          </div>

          <div className="col-md-6 px-0 centerImage">
            <img
              src="./img/logos/VPK Trans.png"
              className="imgslickz"
              alt="#"
              width="70%"
            />
          </div>

          <div className="col-md-6 centered p-md-5 pt-5 pb-5">
            <div>
              <div className="heading">VPK International Trans, s.r.o.</div>
            </div>
          </div>

          <div className="col-md-6 px-0 centerImage">
            <img
              src="./img/logos/buildfit.png"
              className=""
              alt="#"
              width="80%"
            />
          </div>

          <div className="col-md-6 centered p-md-5 pt-5">
            <div>
              <div className="heading">Buildfit s. r. o.</div>
              <p className="mt-3">
                Investično-developerská a projektová spoločnosť so
                špecializáciou predovšetkým na rezidenčné developerské projekty.
              </p>
            </div>
          </div>
        </div>
      </section>
    </Item>

    <Bannercontact />
    <Testimony />
    <Footer />
  </ListContainer>
);
