import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class CustomSlide extends Component {
  render() {
    const { index, ...props } = this.props;
    return <div {...props}></div>;
  }
}

export default class Responsive extends Component {
  render() {
    var settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1900,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 1600,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 2,
            dots: true,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true,
          },
        },
      ],
    };
    return (
      <section
        className="container-fluid  wraper-testimony"
        style={{ backgroundImage: `url(${"./img/testimony.jpg"})` }}
      >
        <div className="row m-10-hor">
          <div className="testimony">
            <Slider {...settings}>
              <CustomSlide className="itm" index={1}>
                <div className="blockquote">
                  <div className="fa"></div>
                  <div className="conesti">
                    <p>
                      Ochrana života, slobody a majetku bola a musí zostať
                      základným záujmom každého spravodlivého zákona.
                    </p>
                    <div className="bytesti">Cesare Beccaria</div>
                  </div>
                </div>
              </CustomSlide>

              <CustomSlide className="itm" index={2}>
                <div className="blockquote">
                  <div className="fa"></div>
                  <div className="conesti">
                    <p>
                      Právo je najvyššia vôľa dobra, ktorá vždy chráni všetko
                      dobro.
                    </p>
                    <div className="bytesti">Marcus Tullius Cicero</div>
                  </div>
                </div>
              </CustomSlide>

              <CustomSlide className="itm" index={3}>
                <div className="blockquote">
                  <div className="fa"></div>
                  <div className="conesti">
                    <p>
                      Základným princípom demokracie je, že každý človek má
                      právo na spravodlivý proces a spravodlivé súdnictvo.
                    </p>
                    <div className="bytesti">Robert F. Kennedy</div>
                  </div>
                </div>
              </CustomSlide>

              <CustomSlide className="itm" index={4}>
                <div className="blockquote">
                  <div className="fa"></div>
                  <div className="conesti">
                    <p>
                      Právo je niečo, čo musíme stále vytvárať, a každá
                      generácia musí pracovať na jeho zachovaní.
                    </p>
                    <div className="bytesti">Oliver Wendell Holmes Jr.</div>
                  </div>
                </div>
              </CustomSlide>

              <CustomSlide className="itm" index={4}>
                <div className="blockquote">
                  <div className="fa"></div>
                  <div className="conesti">
                    <p>
                      Najlepšie a najdôležitejšie zákony sú tie, ktoré ochraňujú
                      lásku v rodine.
                    </p>
                    <div className="bytesti"> Alexis de Tocqueville</div>
                  </div>
                </div>
              </CustomSlide>
            </Slider>
          </div>
        </div>
      </section>
    );
  }
}
