import React from "react";
import posed from "react-pose";
import Slider from "../components/sliderhome";
import Carouselteam from "../components/carouselteam";
import Bannercontact from "../components/bannercontact";
import Pricing from "../components/pricing";
import Testimony from "../components/carouseltestimony";
import Footer from "../components/footer";
import { useEffect } from "react";

const ListContainer = posed.div({
  enter: { staggerChildren: 20 },
  exit: { staggerChildren: 20, staggerDirection: 0 },
});

const Item = posed.section({
  enter: { y: 0, opacity: 1 },
  exit: { y: 5, opacity: 0 },
});

export default () => (
  <ListContainer>
    <Item className="jumbotron jumbomain">
      <Slider />
      <div className="icon-scroll-wraper">
        <div className="icon-scroll">
          <div className="icon-scroll-screen"></div>
        </div>
      </div>
    </Item>

    <section className="container-fluid black">
      <div className="row m-10-hor">
        <div className="col-md-5">
          <div className="heading">
            Naším<span className="br"></span> zameraním je:{" "}
            <span className="br"></span>
          </div>
        </div>

        <div className="col-md-7">
          <div className="content">
            Poskytujeme komplexné právne služby národným aj medzinárodným
            obchodným spoločnostiam ako aj fyzickým osobám. Naši klienti sú
            spravidla naši dlhoroční obchodní partneri, ktorým zabezpečujeme
            individuálny prístup a lojálnosť.
          </div>
          <div className="content">
            Cieľom našej advokátskej kancelárie je byť diskrétnym a spoľahlivým
            obchodným partnerom, na ktorého sa môžu klienti kedykoľvek s dôverou
            obrátiť, nakoľko vedia, že im pomôžeme vyriešiť nielen každodenné
            záležitosti, ale aj tie najnáročnejšie právne problémy.
          </div>
        </div>
      </div>
    </section>

    <section className="container-fluid pt-0 black">
      <div className="row m-10-hor">
        <div className="col-md-4">
          <div className="col-feature">
            {/* <div className='sub-color text-gradient'>
              01.
            </div> */}
            <div className="heading">Obchodné právo</div>
            <div className="content">
              Obchodné právo zahŕňa najmä vzťahy medzi subjektmi v obchodnom
              styku, ako sú napríklad obchodné spoločnosti, podnikajúce fyzické
              osoby, kupujúci-predávajúci, objednávateľ – zhotoviteľ a pod..
              Zahrňuje práva a povinnosti zúčastnených strán, ako aj pravidlá
              pre uzatváranie a vykonávanie obchodných zmlúv, ich ukončenie,
              zabezpečenie záväzkov z nich vyplývajúce.
            </div>
          </div>
        </div>

        <div className="col-md-4">
          <div className="col-feature">
            {/* <div className='sub-color text-gradient'>
              02.
            </div> */}
            <div className="heading">Občianske právo</div>
            <div className="content">
              Občianske právo upravuje najmä problematiku právnych úkonov,
              otázky rodiny, dedičstva, zmluvných vzťahov, majetkových práv a
              povinností, ako aj zodpovednosti za porušenie práv.
            </div>
          </div>
        </div>

        <div className="col-md-4">
          <div className="col-feature">
            {/* <div className='sub-color text-gradient'>
              03.
            </div> */}
            <div className="heading ">Pracovné právo</div>
            <div className="content">
              Pracovné právo upravuje najmä vzťahy medzi zamestnávateľmi a
              zamestnancami v pracovnom prostredí. Zahrňuje pravidlá týkajúce sa
              zamestnávania, odmeňovania, pracovných podmienok, práv a
              povinností zamestnávateľa a zamestnanca a iných pracovných
              súvislostí.
            </div>
          </div>
        </div>

        <div className="col-md-4">
          <div className="col-feature">
            {/* <div className='sub-color text-gradient'>
              03.
            </div> */}
            <div className="heading">Korporátne právo</div>
            <div className="content">
              Zahŕňa pravidlá týkajúce sa vzniku, organizácie, vedenia a
              zrušenia firiem, ako aj vzťahov medzi akcionármi, vedením a
              zamestnancami.
            </div>
          </div>
        </div>

        <div className="col-md-4">
          <div className="col-feature">
            {/* <div className='sub-color text-gradient'>
              03.
            </div> */}
            <div className="heading">Konkurzné právo</div>
            <div className="content">
              Konkurzné právo upravuje procesy riešenia úpadku úpadcu, kedy
              úpadca nemá dostatok majetku na to, aby uspokojil svojich
              veriteľov v plnom rozsahu.
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="container-fluid black_more">
      <div className="row m-10-hor">
        <div className="col-12 text-center">
          <div className="subheading">Základné inforácie o nás</div>
          <div className="heading">Informácie o našom tíme</div>
          <div className="row">
            <div className="col-md-8 mx-auto">
              <p className="content">
                Odbornosť a profesionálny prístup k riešeniu problémov garantujú
                dlhoročné skúsenosti nášho tímu v oblasti poskytovania právnych
                služieb. Náš pracovný tím pozostáva z právnikov, ktorí sú
                pripravení riešiť právne problémy, právne stanoviská,
                pripravovať zmluvy, zastupovať klientov pred súdmi a úradmi na
                celom území SR, vymáhať pohľadávky, exekúcie, zabezpečovať
                komplexné právne služby pre obchodné spoločnosti.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <Bannercontact />
    {/* <Pricing /> */}
    <Testimony />
    <Footer />
  </ListContainer>
);
