import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "@reach/router";
import posed from "react-pose";
import Testimony from "../components/carouseltestimony";
import Footer from "../components/footer";
import emailjs from "emailjs-com";

const ListContainer = posed.div({
  enter: { staggerChildren: 20 },
  exit: { staggerChildren: 20, staggerDirection: 0 },
});

const Item = posed.section({
  enter: { y: 0, opacity: 1 },
  exit: { y: 5, opacity: 0 },
});

const URL = "https://jsonplaceholder.typicode.com/posts";
const required = "Tento údaj je povinný";
const serviceID = "service_0fdewpi";
const templateID = "template_6qiaaug";
const userID = "712yujX6N2V0Xacpp";
export default () => {
  const [submitted, setSubmitted] = useState(false);

  const {
    register,
    handleSubmit,
    setError,
    errors,
    reset,
    formState: { isSubmitting },
  } = useForm();

  const onSubmit = async (data) => {
    emailjs.send(serviceID, templateID, data, userID).then(
      () => {
        setSubmitted(true);
        reset();
      },
      (err) => {
        setError(
          "submit",
          "submitError",
          `Oops! There seems to be an issue! ${err.message}`
        );
      }
    );

    console.log(data);
    try {
      await emailjs.send("service_n6235sr", "template_6qiaaug", data);
    } catch (error) {}
  };

  const showSubmitError = (msg) => <p className="msg-error">{msg}</p>;

  const showThankYou = (
    <div className="msg-confirm mt-5">
      <h3>Vaša správa bola odoslaná.</h3>
    </div>
  );

  const showForm = (
    <form
      className="formcontact"
      onSubmit={handleSubmit(onSubmit)}
      method="post"
    >
      <label htmlFor="name">
        <h5>Meno</h5>
        <input
          type="text"
          name="name"
          id="name"
          placeholder="Vaše meno"
          ref={register({ required })}
          disabled={isSubmitting}
        />
        {errors.name && <div className="msg-error">{errors.name.message}</div>}
      </label>

      <label htmlFor="email">
        <h5>Email</h5>
        <input
          type="email"
          name="email"
          id="email"
          placeholder="vasa@emailova.adresa"
          ref={register({ required })}
          disabled={isSubmitting}
        />
        {errors.email && (
          <div className="msg-error">{errors.email.message}</div>
        )}
      </label>

      <label htmlFor="question">
        <h5>Správa</h5>
        <textarea
          ref={register({ required })}
          name="question"
          id="question"
          rows="3"
          placeholder="Vaša správa"
          disabled={isSubmitting}
        />
        {errors.question && (
          <div className="msg-error">{errors.question.message}</div>
        )}
      </label>

      <div className="submit-wrapper">
        <button type="submit" disabled={isSubmitting}>
          <span>Odoslať</span>
        </button>
      </div>
    </form>
  );

  return (
    <ListContainer>
      <Item
        className="jumbotron breadcumb"
        style={{ backgroundImage: `url(${"./img/bg-2.jpg"})` }}
      >
        <div className="mainbreadcumb">
          <div className="container-fluid">
            <div className="row m-10-hor">
              <div className="col-md-6">
                <h1>Kontaktujte nás</h1>
              </div>
              <div className="col-md-6">
                <div className="list">
                  <Link className="link" to="/home">
                    Domov
                  </Link>
                  <span className="dash">/</span>
                  <span>Kontaktujte nás</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Item>
      <Item className="container-fluid">
        <div className="row m-10-hor">
          <div className="col-md-6">
            <div className="form-side">
              <h2>Napíšte nám</h2>
              <p>Kontaktujte nás prostredníctvom kontaktného formuláru.</p>
              {submitted ? showThankYou : showForm}
              {errors &&
                errors.submit &&
                showSubmitError(errors.submit.message)}
            </div>
          </div>

          <div className="col-md-6 pl-md-5">
            <div className="text-side">
              <h2>Kontaktné informácie</h2>

              <div className="address">
                <div className="heading">BK ADVO PARTNERS, s.r.o.</div>
                <div className="list">
                  <i className="fa fa-map-marker"></i>
                  Štúrova 24, 927 01 Šaľa
                </div>
                <div className="list">
                  <i className="fa fa-envelope-o"></i>
                  <a
                    href="mailto:botorce@advokat.eu.sk"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    botorce@advokat.eu.sk
                  </a>
                </div>
                <div className="list">
                  <i className="fa fa-envelope-o"></i>
                  <a
                    href="mailto:kollar@advokat.eu.sk"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    kollar@advokat.eu.sk
                  </a>
                </div>
                <div className="list">
                  <i className="fa fa-envelope-o"></i>
                  <a
                    href="mailto:kancelaria@advokat.eu.sk"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    kancelaria@advokat.eu.sk
                  </a>
                </div>
                <div className="list">
                  <i className="fa fa-phone"></i>
                  +421 905 268 338
                </div>
                <div className="list">
                  <i className="fa fa-phone"></i>
                  +421 944 290 909
                </div>
              </div>
            </div>
          </div>
        </div>
      </Item>

      <Testimony />
      <Footer />
    </ListContainer>
  );
};
//https://medium.com/weekly-webtips/simple-react-contact-form-without-back-end-9fa06eff52d9
//
