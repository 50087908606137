import React from "react";
import { Link } from "@reach/router";

export default () => (
  <section className="container-fluid">
    <div className="row m-10-hor">
      <div className="col-md-6">
        <img
          src="./img/mocks.jpg"
          alt="imgbanner"
          className="w-100 border-radius"
        />
      </div>
      <div className="col-md-6 centered">
        <div>
          {/* <div className="subheading">
                Môžete sa na nás spolahnúť
              </div>
              {/* <div className="heading">
                Máme dlhoročné skúsenosti
              </div> */}
          {/* <p>
            Pôvodne vykonávala advokáciu konateľka a advokátka JUDr. Lýdia
            Botorče samostatne od roku 2013.
            <span className="br"></span> Od roku 2017 poskytuje právne služby
            prostredníctvom spoločnosti s ručením obmedzeným.
            <span className="br"></span>
          </p> */}
          <Link className="btn " to="/contact">
            <span>Kontaktujte nás</span>
          </Link>
        </div>
      </div>
    </div>
  </section>
);
