import React from "react";

export default () => (
  <footer className="container-fluid black_more">
    <div className="row m-10-hor">
      <div className="col-md-4">
        <div className="footer-col">
          <div className="heading">
            <h2 className="font-weight-bold ">O nás</h2>
          </div>
          <div className="content">
            <p>
              Poskytujeme komplexné právne služby národným aj medzinárodným
              obchodným spoločnostiam ako aj fyzickým osobám. Naši klienti sú
              spravidla naši dlhoroční obchodní partneri, ktorým zabezpečujeme
              individuálny prístup a lojálnosť.
            </p>
          </div>
        </div>
      </div>
      <div className="col-md-2">
        <div className="footer-col">
          <div className="heading">Užitočné odkazy</div>
          <div className="content">
            <div className="link">Novinky</div>
            <div className="link">Referencie</div>
            <div className="link">Kontaktujte nás</div>
          </div>
        </div>
      </div>

      <div className="col-md-4">
        <div className="footer-col">
          <div className="heading">Sledujte nás</div>
          <div className="content">
            <p>Nachádzame sa aj na sociálnych sieťach.</p>
            <div className="socialicon centered">
              <a
                href="https://www.facebook.com/profile.php?id=100063699741886"
                className="fa fa-facebook-f "
              ></a>
            </div>
            {
              //Change the link to your facebook page
            }

            {/* <div className="socialicon">
              <i className="fa fa-linkedin"></i>
            </div>
            <div className="socialicon">
              <i className="fa fa-twitter"></i>
            </div>
            <div className="socialicon">
              <i className="fa  fa-instagram"></i>
            </div> */}
          </div>
        </div>
      </div>
    </div>
    <div className="subfooter centered">
      <div className="col-md-6 centered">
        <div className="content">
          © Copyright 2022
          <span className="font-weight-bold"> BK ADVO PARTNERS, s.r.o. </span>
        </div>
      </div>
    </div>
  </footer>
);
