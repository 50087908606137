import React from "react";
import Slider from "react-animated-slider";
import "react-animated-slider/build/horizontal.css";

const content = [
  {
    title: "BK ADVO PARTNERS, s.r.o.",
    description: "Advokátska kancelária",
    link: "//google.com",
    image: "./img/bg-2.jpg",
  },
  {
    title: "BK ADVO PARTNERS, s.r.o.",
    description: "Advokátska kancelária",
    link: "//google.com",
    image: "./img/bg-4.jpg",
  },
  {
    title: "BK ADVO PARTNERS, s.r.o.",
    description: "Advokátska kancelária",
    link: "//google.com",
    image: "./img/bg-3.jpg",
  },
  {
    title: "BK ADVO PARTNERS, s.r.o.",
    description: "Advokátska kancelária",
    link: "//google.com",
    image: "./img/bg-1.jpg",
  },
];

export default () => (
  <Slider className="slider-wrapper" autoplay={10000}>
    {content.map((item, index) => (
      <div
        key={index}
        className="slider-content"
        style={{ background: `url('${item.image}') no-repeat center center` }}
      >
        <div className="inner">
          <h1>{item.title}</h1>
          <p>{item.description}</p>
        </div>
      </div>
    ))}
  </Slider>
);
