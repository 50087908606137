import React from "react";
import { Link } from "@reach/router";
import posed from "react-pose";
import Testimony from "../components/carouseltestimony";
import Footer from "../components/footer";

const ListContainer = posed.div({
  enter: { staggerChildren: 20 },
  exit: { staggerChildren: 20, staggerDirection: 0 },
});

const Item = posed.section({
  enter: { y: 0, opacity: 1 },
  exit: { y: 5, opacity: 0 },
});

export default () => (
  <ListContainer>
    <Item
      className="jumbotron breadcumb"
      style={{ backgroundImage: `url(${"./img/bg-5.jpg"})` }}
    >
      <div className="mainbreadcumb">
        <div className="container-fluid">
          <div className="row m-10-hor">
            <div className="col-md-6">
              <h1>O nás</h1>
            </div>
            <div className="col-md-6">
              <div className="list">
                <Link className="link" to="/home">
                  Domov
                </Link>
                <span className="dash">/</span>
                <span>O nás</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Item>

    <Item className="container-fluid black">
      {/* <div className="row m-10-hor">
        <div className="col-md-5">
          <div className="subheading">BK Advo Partners s.r.o.</div>
          <div className="heading">
            Start Business <span className="br"></span> Enterprisess
          </div>
        </div>

        <div className="col-md-7">
          <div className="content">
            Poskytujeme komplexné právne služby národným aj medzinárodným
            obchodným spoločnostiam ako aj fyzickým osobám. Naši klienti sú
            spravidla naši dlhoroční obchodní partneri, ktorým zabezpečujeme
            individuálny prístup a lojálnosť.
          </div>
          <div className="content">
            Curabitur mollis bibendum luctus. Duis suscipit vitae dui sed
            suscipit. Quisque vitae sodales lectus, vel tristique risus
            faucibus.
          </div>
        </div>
      </div> */}
    </Item>

    <section className="container-fluid pt-0">
      <div className="row m-10-hor">
        <div className="col-md-6 px-0">
          <img
            src="./img/blog.jpg"
            className="blogimage border-radius"
            alt="#"
          />
        </div>

        <div className="col-md-6 centered p-md-5 pt-5">
          <div>
            {/* <div className="subheading">January 14, 2019</div> */}
            <div className="heading">Poskytujeme</div>
            <p className="mt-3">
              Komplexné právne služby národným aj medzinárodným obchodným
              spoločnostiam ako aj fyzickým osobám. Naši klienti sú spravidla
              naši dlhoroční obchodní partneri, ktorým zabezpečujeme
              individuálny prístup a lojálnosť.
            </p>
            {/* <Link className="btn" to="">
              <span>More Detail</span>
            </Link> */}
          </div>
        </div>

        <div className="col-md-6 centered p-md-5 pt-5 pb-5">
          <div>
            {/* <div className="subheading">January 22, 2019</div> */}
            <div className="heading">Náš cieľ</div>
            <p className="mt-3">
              Cieľom našej advokátskej kancelárie je byť diskrétnym a
              spoľahlivým obchodným partnerom, na ktorého sa môžu klienti
              kedykoľvek s dôverou obrátiť, nakoľko vedia, že im pomôžeme
              vyriešiť nielen každodenné záležitosti, ale aj tie najnáročnejšie
              právne problémy
            </p>
            {/* <Link className="btn" to="">
              <span>More Detail</span>
            </Link> */}
          </div>
        </div>

        <div className="col-md-6 px-0">
          <img
            src="./img/blog1.jpg"
            className="blogimage border-radius"
            alt="#"
          />
        </div>
      </div>
    </section>

    <Testimony />
    <Footer />
  </ListContainer>
);
